import { useState, type ReactNode } from "react";
import { Button } from "@/components/input/Button";
import ErrorBox from "@/components/input/ErrorBox";
import { useAuthStore } from "@/services/Cognito/authStore";
import { handleSignIn } from "@/services/Cognito/handlers";
import { useForm, type SubmitHandler } from "react-hook-form";

type FormInputs = {
  email: string;
  password: string;
};

type Props = {
  header?: ReactNode;
  footer?: ReactNode;
};

const DefaultHeader = () => {
  return (
    <>
      <h3 className="text-left text-3xl font-semibold">Sign In</h3>
      <hr className="my-4 border-gray-900"></hr>
    </>
  );
};

const DefaultFooter = () => {
  const setRoute = useAuthStore((ctx) => ctx.setRoute);
  const setEmail = useAuthStore((ctx) => ctx.setSignUpEmail);
  return (
    <>
      <div className="flex justify-center text-sm">
        <span className="pr-2">Dont have an account?</span>
        <a
          className="cursor-pointer font-bold text-[#6852c0] hover:text-[#7862d0]"
          onClick={() => setRoute("signUp")}
        >
          Sign Up
        </a>
      </div>
      <a
        className="flex cursor-pointer justify-center text-sm  font-bold text-[#6852c0] hover:text-[#7862d0]"
        onClick={() => {
          setEmail(undefined);

          setRoute("resetPassword");
        }}
      >
        Forgot your Password?
      </a>
    </>
  );
};
export const SignInForm = (props: Props) => {
  const [submitError, setSubmitError] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit } = useForm<FormInputs>();
  const setRoute = useAuthStore((ctx) => ctx.setRoute);
  const setSignUpEmail = useAuthStore((ctx) => ctx.setSignUpEmail);
  const hideAuthenticator = useAuthStore((ctx) => ctx.hideAuthenticator);

  const onSubmit: SubmitHandler<FormInputs> = async (form) => {
    try {
      const res = await handleSignIn({
        username: form.email,
        password: form.password,
      });
      if (res.isSignedIn) return hideAuthenticator();
      switch (res.nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
        case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION":
        case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
        case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          console.error(`${res.nextStep.signInStep} not handled`);
          break;
        case "CONFIRM_SIGN_UP":
          setSignUpEmail(form.email);
          setRoute("verifyEmail");
          break;
        case "RESET_PASSWORD":
          setRoute("resetPassword");
          break;
        case "DONE":
          hideAuthenticator();
          break;
      }
    } catch (error) {
      if (error instanceof Error) setSubmitError(error.message);
      else console.error(error);
    }
  };

  return (
    <div className="flex w-full flex-col rounded-xl border border-gray-300 bg-white px-8 pb-4 pt-8 sm:w-[32rem]">
      {props.header || <DefaultHeader />}

      <form
        className="flex w-full flex-col space-y-3"
        onSubmit={(event) => {
          void handleSubmit(onSubmit)(event);
        }}
      >
        <div className="grid space-y-2">
          <label>Email</label>
          <input
            type="email"
            className="rounded border border-gray-900 p-2 text-center"
            placeholder="Enter your Email"
            {...register("email", { required: true })}
          />
        </div>
        <div className="grid space-y-2">
          <label>Password</label>
          <div className="flex w-full">
            <input
              type={showPassword ? "text" : "password"}
              className="w-full rounded border border-gray-900 p-2 text-center"
              placeholder="Enter your Password"
              {...register("password", { required: true })}
            />
          </div>
        </div>
        <div
          className="flex select-none space-x-2"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <input
            type="checkbox"
            className="scale-125"
            checked={showPassword}
            readOnly
          />
          <label>Show Password</label>
        </div>
        <Button type="submit">Sign in</Button>
        {submitError && (
          <ErrorBox
            message={submitError}
            hide={() => setSubmitError(undefined)}
          />
        )}
        <div>{props.footer || <DefaultFooter />}</div>
      </form>
    </div>
  );
};

export default SignInForm;
