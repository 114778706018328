"use client";

import { useState, type ReactNode } from "react";
import Link from "next/link";
import { Button } from "@/components/input/Button";
import { useAuthStore } from "@/services/Cognito/authStore";
import { handleSignUp } from "@/services/Cognito/handlers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, type SubmitHandler } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import ErrorBox from "../input/ErrorBox";
import { passwordSchema } from "./types";

const formSchema = z
  .object({
    email: z.string().email(),
    // usually react form is helpful but has no way of outputting all errors at once
    // so currently solving this issue by using superRefine and outputting the entire error as a string and than parsing again in component
    password: z.string().superRefine((val, ctx) => {
      const res = passwordSchema.safeParse(val);
      if (!res.success) {
        const err = res.error.issues.map((issue) => issue.message);
        ctx.addIssue({
          code: "custom",
          message: err.toString(),
        });
      }
    }),
    confirmPassword: z.string(),
    agreeToTerms: z.boolean(),
  })
  .superRefine(({ password, confirmPassword, agreeToTerms }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        path: ["confirmPassword"],
        message: "Your passwords must match",
      });
    }
    if (agreeToTerms !== true) {
      ctx.addIssue({
        code: "custom",
        path: ["agreeToTerms"],
        message: "You must agree to Terms & Conditions and Privacy Policy",
      });
    }
  });

type FormInputs = z.infer<typeof formSchema>;

type Props = {
  header?: ReactNode;
  navigateToVerifyEmail?: () => void;
  navigateToSignIn?: () => void;
};

const DefaultHeader = () => {
  return (
    <>
      <h3 className="text-left text-3xl font-semibold">Sign Up</h3>
      <hr className="my-4 border-gray-900"></hr>
    </>
  );
};

const SignUpForm = (props: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const setRoute = useAuthStore((ctx) => ctx.setRoute);
  const setEmail = useAuthStore((ctx) => ctx.setSignUpEmail);
  const [submitError, setSubmitError] = useState<string | undefined>();

  const handleNavigateToSignIn = () =>
    props.navigateToSignIn ? props.navigateToSignIn() : setRoute("signIn");
  const handleNavigateToVerifyEmail = () =>
    props.navigateToVerifyEmail
      ? props.navigateToVerifyEmail()
      : setRoute("verifyEmail");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      await handleSignUp({
        username: data.email,
        password: data.password,
        email: data.email,
      });
      setEmail(data.email);
      handleNavigateToVerifyEmail();
    } catch (err) {
      console.error(err);
      if (err instanceof Error) setSubmitError(err.message);
    }
  };

  return (
    <div className="flex w-full flex-col rounded-xl border border-gray-300 bg-white px-8 pb-4 pt-8 sm:w-[32rem]">
      {props.header || <DefaultHeader />}
      {/* Weird logic cause this https://github.com/orgs/react-hook-form/discussions/8622#discussioncomment-5774089  */}
      <form
        className="flex w-full flex-col space-y-3"
        onSubmit={(event) => {
          void handleSubmit(onSubmit)(event);
        }}
      >
        <div className="grid space-y-2">
          <label>Email</label>
          <input
            type="email"
            className="rounded border border-gray-900 p-2 text-center"
            placeholder="Enter your Email"
            {...register("email", { required: true })}
          />
        </div>
        <div className="grid space-y-2">
          <label>Password</label>
          <div className="flex w-full">
            <input
              type={showPassword ? "text" : "password"}
              className={twMerge(
                "w-full rounded border p-2 text-center",
                errors.password ? "border-red-700" : "border-gray-900",
              )}
              placeholder="Enter your Password"
              {...register("password", { required: true })}
            />
          </div>
          <div className="flex flex-col">
            {errors.password?.message?.split(",").map((err) => (
              <span className="text-red-700" key={err}>
                {err}
              </span>
            ))}
          </div>
        </div>
        <div className="grid space-y-2">
          <label>Confirm Password</label>
          <div className="flex w-full">
            <input
              type={showPassword ? "text" : "password"}
              className={twMerge(
                "w-full rounded border p-2 text-center",
                errors.confirmPassword ? "border-red-700" : "border-gray-900",
              )}
              placeholder="Enter your Password"
              {...register("confirmPassword", { required: true })}
            />
          </div>
          <div>
            {errors.confirmPassword && (
              <span className="text-red-700">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>
        </div>

        <div
          className="flex select-none items-center space-x-2 hover:cursor-pointer"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <input
            type="checkbox"
            className="scale-125 hover:cursor-pointer"
            checked={showPassword}
            readOnly
          />
          <label className="hover:cursor-pointer">Show Password</label>
        </div>
        <div className="flex flex-col">
          <div className="flex select-none items-center space-x-2 hover:cursor-pointer">
            <input
              type="checkbox"
              className="scale-125"
              readOnly
              required
              {...register("agreeToTerms", { required: true })}
            />
            <label>
              <span>I agree to the </span>
              <Link
                target="_blank"
                href={"tos"}
                className="cursor-pointer font-medium text-[#6852c0] hover:text-[#7862d0]"
              >
                Terms & Conditions{" "}
              </Link>
              <span>and </span>
              <Link
                href={"privacy"}
                target="_blank"
                className="cursor-pointer font-medium text-[#6852c0] hover:text-[#7862d0]"
              >
                Privacy Policy
              </Link>
              .
            </label>
          </div>
          {errors.agreeToTerms && (
            <span className="text-red-700">{errors.agreeToTerms.message}</span>
          )}
        </div>

        <Button type="submit">Sign Up</Button>
        {submitError && (
          <ErrorBox
            message={submitError}
            hide={() => setSubmitError(undefined)}
          />
        )}
        <div>
          <div className="flex justify-center text-sm">
            <span className="pr-2">Already have an account?</span>
            <a
              onClick={handleNavigateToSignIn}
              className="cursor-pointer font-bold text-[#6852c0] hover:text-[#7862d0]"
            >
              Sign In
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
