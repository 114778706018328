import XIcon from "public/svgs/x.svg";

const ErrorBox = ({ message, hide }: { message: string; hide: () => void }) => {
  if (!message) return;
  return (
    <div className="flex bg-red-500 bg-opacity-30 py-2 text-center text-red-800">
      <div className="flex grow items-center justify-center pl-2">
        <span>{message}</span>
      </div>
      <div className="m-2">
        <button
          className="group rounded bg-transparent bg-opacity-30 p-1.5 hover:bg-red-300"
          onClick={hide}
        >
          <XIcon className="group-hover:text-white" />
        </button>
      </div>
    </div>
  );
};

export default ErrorBox;
