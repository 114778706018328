"use client";

import { useState } from "react";
import { Button } from "@/components/input/Button";
import ErrorBox from "@/components/input/ErrorBox";
import { useAuthStore, type AuthRoute } from "@/services/Cognito/authStore";
import {
  handleConfirmResetPassword,
  handleResetPassword,
} from "@/services/Cognito/handlers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, type SubmitHandler } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { passwordSchema } from "./types";

const formSchema = z
  .object({
    code: z.string(),
    // usually react form is helpful but has no way of outputting all errors at once
    // so currently solving this issue by using superRefine and outputting the entire error as a string and than parsing again in component
    password: z.string().superRefine((val, ctx) => {
      const res = passwordSchema.safeParse(val);
      if (!res.success) {
        const err = res.error.issues.map((issue) => issue.message);
        ctx.addIssue({
          code: "custom",
          message: err.toString(),
        });
      }
    }),
    confirmPassword: z.string(),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        path: ["confirmPassword"],
        message: "Your passwords must match",
      });
    }
  });

type FormInputs = z.infer<typeof formSchema>;

const ResetPasswordForm = ({
  email,
  navigateToSignIn,
}: {
  email: string;
  navigateToSignIn?: () => void;
}) => {
  const setRoute = useAuthStore((ctx) => ctx.setRoute);
  const [submitError, setSubmitError] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log("submit");
    try {
      await handleConfirmResetPassword({
        username: email,
        newPassword: data.password,
        confirmationCode: data.code,
      });
      navigateToSignIn ? navigateToSignIn() : setRoute("signIn");
    } catch (e) {
      if (e instanceof Error) setSubmitError(e.message);
      else console.error(e);
    }
  };

  return (
    <div className="flex w-full flex-col rounded-xl border border-gray-300 bg-white px-8 pb-4 pt-8 sm:w-[32rem]">
      <h3 className="mb-4 text-3xl font-semibold">Reset Password</h3>
      <form
        className="flex w-full flex-col space-y-3"
        onSubmit={(event) => {
          void handleSubmit(onSubmit)(event);
        }}
      >
        <div className="grid space-y-2">
          <label>Code *</label>
          <input
            type="number"
            className="rounded border border-gray-900 p-2 text-center"
            placeholder="Code"
            {...register("code", { required: true })}
          />
        </div>
        <div className="grid space-y-2">
          <label>New Password</label>
          <div className="flex w-full">
            <input
              type={showPassword ? "text" : "password"}
              className={twMerge(
                "w-full rounded border p-2 text-center",
                errors.password ? "border-red-700" : "border-gray-900",
              )}
              placeholder="New Password"
              {...register("password", { required: true })}
            />
          </div>
          <div className="flex flex-col">
            {errors.password?.message?.split(",").map((err) => (
              <span className="text-red-700" key={err}>
                {err}
              </span>
            ))}
          </div>
        </div>
        <div className="grid space-y-2">
          <label>Confirm Password</label>
          <div className="flex w-full">
            <input
              type={showPassword ? "text" : "password"}
              className={twMerge(
                "w-full rounded border p-2 text-center",
                errors.confirmPassword ? "border-red-700" : "border-gray-900",
              )}
              placeholder="Confirm Password"
              {...register("confirmPassword", { required: true })}
            />
          </div>
          <div>
            {errors.confirmPassword && (
              <span className="text-red-700">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>
        </div>
        <div
          className="flex select-none space-x-2"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <input
            type="checkbox"
            className="scale-125"
            checked={showPassword}
            readOnly
          />
          <label>Show Password</label>
        </div>
        {submitError && (
          <ErrorBox
            message={submitError}
            hide={() => setSubmitError(undefined)}
          />
        )}
        <Button type="submit">Submit</Button>
        <div>
          <button
            className="cursor-pointer font-bold text-[#6852c0] hover:text-[#7862d0]"
            onClick={() => {
              email &&
                handleResetPassword({ username: email }).catch(console.error);
            }}
          >
            Resend Code
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
