import {
  autoSignIn,
  confirmResetPassword,
  confirmSignUp,
  resendSignUpCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
} from "aws-amplify/auth";

import { analytics } from "../Analytics";
import { useAuthStore } from "./authStore";

async function handleSignUp({
  username,
  password,
  email,
}: {
  username: string;
  password: string;
  email: string;
}) {
  try {
    const { userId } = await signUp({
      username,
      password,
      options: {
        userAttributes: {
          email,
        },
        autoSignIn: true,
      },
    });
    if (userId) analytics.identify(userId).catch(console.error);
    analytics.track("signUp").catch(console.error);
  } catch (e) {
    analytics.track("signUp_failure").catch(console.error);
    throw e;
  }
}

async function handleSignIn({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  const res = await signIn({ username, password });
  return res;
}

async function handleConfirmSignUp(input: {
  username: string;
  confirmationCode: string;
}) {
  const res = await confirmSignUp(input);
  analytics.track("confirmSignUp").catch(console.error);
  return res;
}

async function handleResendConfirmationCode(username: string) {
  return await resendSignUpCode({ username });
}

async function handleAutoSignIn() {
  try {
    await autoSignIn();
  } catch (error) {
    console.log(error);
  }
}
// pass in true to sign out from all devices
async function handleSignOut(global = false) {
  try {
    await signOut({ global });
    useAuthStore.setState({
      route: "signUp",
      authenticatorVisible: false,
      signUpEmail: undefined,
      session: undefined,
      user: undefined,
      authUser: undefined,
      status: "unauthenticated",
    });
    analytics.track("signOut").catch(console.error);
    analytics.reset().catch(console.error);
  } catch (error) {
    console.error(error);
  }
}

async function handleResetPassword({
  username,
  clientMetadata,
}: {
  username: string;
  clientMetadata?: Record<string, string>;
}) {
  try {
    const result = await resetPassword({
      username,
      options: { clientMetadata },
    });
    analytics.track("forgotPassword").catch(console.error);
    return result.nextStep.codeDeliveryDetails;
  } catch (e) {
    analytics.track("forgotPassword_failure").catch(console.error);
    throw e;
  }
}

async function handleConfirmResetPassword({
  username,
  newPassword,
  confirmationCode,
  clientMetadata,
}: {
  username: string;
  newPassword: string;
  confirmationCode: string;
  clientMetadata?: Record<string, string>;
}) {
  try {
    await confirmResetPassword({
      username,
      newPassword,
      confirmationCode,
      options: {
        clientMetadata,
      },
    });
    analytics.track("forgotPasswordSubmit").catch(console.error);
    return "Password was changed successfully.";
  } catch (err) {
    analytics.track("forgotPasswordSubmit_failure").catch(console.error);
    throw err;
  }
}

export {
  handleAutoSignIn,
  handleSignUp,
  handleSignIn,
  handleConfirmSignUp,
  handleResendConfirmationCode,
  handleSignOut,
  handleResetPassword,
  handleConfirmResetPassword,
};
