// @ts-expect-error Library does not provide types https://getanalytics.io/plugins/segment/
import Segment from "@analytics/segment";
import { type AnalyticsPlugin } from "analytics";

type Parameters = {
  writeKey: string;
  disableAnonymousTraffic?: boolean;
  customScriptSrc?: boolean;
};

type SegmentPlugin = (x: Parameters) => AnalyticsPlugin;

export const segment = Segment as SegmentPlugin;
const writeKey =
  process.env.NODE_ENV === "production"
    ? "FxHpFUNw8A1wF7M5gJ6TuOuvY5H1MbnH"
    : "hWmm9qRlML9WJFRTGYS1pY8hIV2bemEc";
export const segmentPlugin = segment({ writeKey });
