import { type AnalyticsPlugin } from "analytics";

type BaseContext = {
  abort: () => void;
  config: unknown;
  payload: {
    anonymousId: string;
    meta: {
      hasCallback: boolean;
      rid: string;
      ts: number;
    };
    options: unknown;
    traits: unknown;
    type: "string";
    userId: string;
  };
};
type PageContext = BaseContext & {
  payload: {
    userId: string;
    properties: {
      hash: string;
      height: number;
      path: string;
      referrer: string;
      search: string;
      title: string;
      url: string;
      width: number;
    };
  };
  type: "string";
};

type TrackContext = BaseContext & {
  payload: {
    event: string;
    properties: Record<string, unknown>;
  };
};

type IdentifyContext = BaseContext & {};
const loggerPlugin: AnalyticsPlugin = {
  name: "logger",
  page: (ctx: PageContext) => {
    console.log(
      `%c Analytics `,
      "color: white; background-color: #0aa12d; font-weight:bold",
      "Page",
      ctx.payload.properties.path,
    );
  },
  track: (ctx: TrackContext) => {
    console.log(
      `%c Analytics `,
      "color: white; background-color: #0aa12d; font-weight:bold",
      "Track",
      ctx.payload.event,
      ctx.payload.properties,
    );
  },
  identify: (ctx: IdentifyContext) => {
    console.log(
      `%c Analytics `,
      "color: white; background-color: #0aa12d; font-weight:bold",
      "Identify",
      ctx.payload.userId,
    );
  },
};

export default loggerPlugin;
