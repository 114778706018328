import {
  fetchAuthSession,
  getCurrentUser,
  type AuthUser,
} from "aws-amplify/auth";
import superjson from "superjson";
import { create } from "zustand";

import { type User } from "@matan/database";

export type AuthRoute = "signIn" | "signUp" | "resetPassword" | "verifyEmail";
type AuthSession = Awaited<ReturnType<typeof fetchAuthSession>>;
// type tokens = AuthSession["tokens"];
type AuthStore = {
  session: AuthSession | undefined;
  authUser: AuthUser | undefined;
  user: User | undefined;
  status: "idle" | "configuring" | "unauthenticated" | "authenticated";
  roles: Array<string> | undefined;
  // authenticator
  signUpEmail: string | undefined;
  setSignUpEmail: (email: string | undefined) => void;
  route: AuthRoute;
  setRoute: (route: AuthRoute) => void;
  authenticatorVisible: boolean;
  showAuthenticator: () => void;
  hideAuthenticator: () => void;
  // hydration
  hydrate: () => Promise<void>;
};

export const useAuthStore = create<AuthStore>((set, get) => ({
  session: undefined,
  authUser: undefined,
  user: undefined,
  status: "idle",
  roles: undefined,
  // authenticator
  signUpEmail: undefined,
  setSignUpEmail: (signUpEmail) => set({ signUpEmail }),
  route: "signIn",
  setRoute: (route) => set({ route }),
  authenticatorVisible: false,
  showAuthenticator: () =>
    !get().authenticatorVisible && set({ authenticatorVisible: true }),
  hideAuthenticator: () =>
    get().authenticatorVisible && set({ authenticatorVisible: false }),

  hydrate: async () => {
    let status = get().status;
    if (status === "configuring" || status === "authenticated") return;
    useAuthStore.setState({
      status: "configuring",
    });
    const hydrateAuthSession = async () => {
      const session = await fetchAuthSession();
      const roles = session.tokens?.accessToken.payload[
        "cognito:groups"
      ] as Array<string>;
      set({ session });
      set({ roles });
      // console.log("session", session);
    };
    const hydrateAuthUser = async () => {
      const authUser = await getCurrentUser();
      set({ authUser, status: "authenticated" });
      // console.log("authuser", authUser);
    };
    const hydrateUser = async () => {
      const res = await fetch("/api/auth/user");
      const user = superjson.parse<User | undefined>(
        (await res.json()) as string,
      );
      if (user) set({ user });
      // console.log("db user", user);
    };

    await Promise.allSettled([
      hydrateAuthSession(),
      hydrateAuthUser(),
      hydrateUser(),
    ]);
    status = get().status;
    if (status === "configuring") set({ status: "unauthenticated" });
  },
}));
