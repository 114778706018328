import { z } from "zod";

export const passwordSchema = z
  .string()
  .min(8, { message: "Password must have at least 8 characters" })
  .regex(/[a-z]/, { message: "Password must have lower case letters" })
  .regex(/\d/, { message: "Password must have numbers" })
  .regex(/[^A-Za-z0-9]/, {
    message: "Password must have special characters",
  })
  .regex(/[A-Z]/, { message: "Password must have upper case letters" });
