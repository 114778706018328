"use client";

import { useState } from "react";
import { Button } from "@/components/input/Button";
import ErrorBox from "@/components/input/ErrorBox";
import {
  handleAutoSignIn,
  handleConfirmSignUp,
  handleResendConfirmationCode,
} from "@/services/Cognito/handlers";
import { useForm, type SubmitHandler } from "react-hook-form";

type FormInputs = {
  confirmationCode: number;
};

const VerifyEmailForm = ({
  email,
  onSuccess,
}: {
  email: string;
  onSuccess?: () => void;
}) => {
  const [error, setError] = useState<Error | undefined>();
  const { register, handleSubmit } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    // if (!email || !password) return router.replace("/auth/signIn");
    try {
      const res = await handleConfirmSignUp({
        username: email,
        confirmationCode: data.confirmationCode.toString(),
      });
      switch (res.nextStep.signUpStep) {
        case "CONFIRM_SIGN_UP":
          throw new Error("Failed to verify email. Please try again");
        case "DONE":
        case "COMPLETE_AUTO_SIGN_IN":
          await handleAutoSignIn();
          console.log("auto signin complete");
          if (typeof onSuccess === "function") onSuccess();
          console.log("handled onSuccess");
          break;
      }
    } catch (error: unknown) {
      if (!(error instanceof Error)) {
        setError(new Error("Failed to verify email. Please try again"));
        return;
      }
      if (error.message.includes("User is already confirmed")) {
        await handleAutoSignIn();
        if (typeof onSuccess === "function") onSuccess();
        return;
      }
      setError(error);
    }
  };

  function handleResendCode() {
    handleResendConfirmationCode(email).catch(console.error);
  }

  return (
    <div className="flex w-full flex-col rounded-xl border border-gray-300 bg-white px-8 pb-4 pt-8 sm:w-[32rem]">
      <h3 className="mb-4 text-2xl font-semibold">Check your email</h3>
      <div className="text-justify">
        <span>
          Your code is on the way. To log in, enter the code we emailed to{" "}
          {email}. It may take a minute to arrive.
        </span>
      </div>
      {/* Weird logic cause this https://github.com/orgs/react-hook-form/discussions/8622#discussioncomment-5774089  */}
      <form
        onSubmit={(event) => {
          void handleSubmit(onSubmit)(event);
        }}
        className="my-4 flex w-full flex-col space-y-3"
      >
        <label className="grid">
          Confirmation Code
          <span className="text-sm font-light text-gray-500">
            Can&apos;t find the email? Check your spam folder
          </span>
        </label>

        <input
          type="text"
          className="rounded border border-gray-900 p-2 text-center"
          {...register("confirmationCode", { required: true })}
        />
        {error && (
          <ErrorBox message={error.message} hide={() => setError(undefined)} />
        )}

        <Button type="submit" className="rounded-lg text-base">
          Confirm
        </Button>
        <button
          onClick={handleResendCode}
          type="button"
          className="rounded-lg border border-black bg-transparent py-1.5 text-black hover:border-purple-400 hover:bg-purple-100"
        >
          Resend Code
        </button>
      </form>
    </div>
  );
};
export default VerifyEmailForm;
