import { useState } from "react";
import { Button } from "@/components/input/Button";
import ErrorBox from "@/components/input/ErrorBox";
import { useAuthStore } from "@/services/Cognito/authStore";
import { handleResetPassword } from "@/services/Cognito/handlers";
import { useForm, type SubmitHandler } from "react-hook-form";

type Inputs = {
  email: string;
};

type Props = {
  navigateToSignIn?: () => void;
};
const ForgotPasswordForm = (props: Props) => {
  const setRoute = useAuthStore((ctx) => ctx.setRoute);
  const setEmail = useAuthStore((ctx) => ctx.setSignUpEmail);
  const navigateToSignIn = () =>
    props.navigateToSignIn ? props.navigateToSignIn() : setRoute("signIn");

  const [submitError, setSubmitError] = useState<string | undefined>();
  const { register, handleSubmit } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await handleResetPassword({ username: data.email });
      setEmail(data.email);
    } catch (e) {
      if (e instanceof Error) setSubmitError(e.message);
      else console.error(e);
    }
  };

  return (
    <div className="flex w-full flex-col rounded-xl border border-gray-300 bg-white px-8 pb-4 pt-8 sm:w-[32rem]">
      <h3 className="mb-4 text-3xl font-semibold">Forgot Password</h3>
      <form
        className="flex w-full flex-col space-y-3"
        onSubmit={(event) => void handleSubmit(onSubmit)(event)}
      >
        <div className="grid space-y-2">
          <label>Enter your email</label>
          <input
            type="email"
            className="rounded border border-gray-900 p-2 text-center"
            placeholder="Enter your Email"
            {...register("email", { required: true })}
          />
        </div>

        {submitError && (
          <ErrorBox
            message={submitError}
            hide={() => setSubmitError(undefined)}
          />
        )}

        <Button type="submit" className="rounded-lg text-base">
          Send Code
        </Button>
        <a
          className="cursor-pointer font-bold text-[#6852c0] hover:text-[#7862d0]"
          onClick={navigateToSignIn}
        >
          Back to Sign in
        </a>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
