import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

const button = cva(["focus:outline-none rounded-lg select-none"], {
  variants: {
    variant: {
      brown: [
        "text-white",
        "bg-brown-500",
        "hover:bg-brown-300",
        "focus:bg-brown-300",
        "focus:ring-brown-100",
      ],
      purple: [
        "text-white",
        "bg-[#6852c0]",
        "hover:bg-[#5236c2]",
        "focus:bg-[#5236c2]",
        "focus:ring-[#9185bf]",
      ],
      red: [
        "bg-[#f12c2c]",
        "hover:bg-[#fa0404]",
        "focus:bg-[#fa0404]",
        "focus:ring-[#f47e7e]",
        "text-white",
        "disabled:bg-zinc-400",
      ],
      grey: [
        "bg-zinc-400",
        "hover:bg-zinc-500",
        "focus:bg-zinc-500",
        "focus:ring-zinc-300",
        "text-white",
      ],
      lightGrey: [
        "bg-zinc-300",
        "hover:bg-zinc-400",
        "focus:bg-zinc-400",
        "focus:ring-zinc-200",
        "text-white",
      ],
      particleImg: [
        "shadow-md",
        "bg-zinc-300",
        "hover:bg-zinc-400",
        "focus:bg-zinc-400",
        "focus:ring-zinc-200",
        "text-white",
      ],
      darkGrey: [
        "bg-zinc-700",
        "hover:bg-zinc-800",
        "focus:bg-zinc-800",
        "focus:ring-zinc-600",
        "text-white",
      ],
      green: [
        "bg-green-500",
        "hover:bg-green-600",
        "focus:bg-green-600",
        "focus:ring-green-400",
        "text-white",
      ],
      darkGreen: [
        "bg-green-700",
        "hover:bg-green-800",
        "focus:bg-green-800",
        "focus:ring-green-600",
        "text-white",
      ],
      veryDarkGreen: [
        "bg-green-800",
        "hover:bg-green-900",
        "focus:bg-green-900",
        "focus:ring-green-700",
        "text-white",
      ],
      lightGreen: [
        "bg-green-400",
        "hover:bg-green-500",
        "focus:bg-green-500",
        "focus:ring-green-300",
        "text-white",
      ],
      opaquePlaceholder: [
        //useful as a placeholder or minor text positioned same as button
        "transparent",
        "hover:transparent",
        "focus:transparent",
        "text-black",
        "cursor-default",
      ],
      opaquePlaceholderSelected: [
        //useful as a placeholder or minor text positioned same as button
        "ring-1",
        "transparent",
        "hover:transparent",
        "focus:transparent",
        "text-black",
        "cursor-default",
      ],
      outlinePurple: [
        "border",
        "border-[#6852c0]",
        "text-[#6852c0]",
        "hover:bg-[#5236c21c]",
        "hover:border-[#5236c2]",
      ],
      outlineRed: [
        "border",
        "border-[#f12c2c]",
        "hover:border-[#fa0404]",
        "text-[#f12c2c]",
        "hover:bg-[#f12c2c20]",
      ],
      filledRed: [
        "border",
        "border-[#f12c2c]",
        "hover:border-[#fa0404]",
        "bg-[#f12c2c50]",
        "text-[#f12c2c]",
        "hover:bg-[#f12c2c20]",
      ],
    },
    size: {
      sm: ["px-2", "py-.5", "focus:ring-2", "text-md"],
      md: ["px-4", "py-1", "focus:ring-4", "text-lg"],
      lg: ["px-6", "py-2", "focus:ring-4", "text-2xl font-bold"],
    },
  },
  defaultVariants: {
    variant: "purple",
    size: "md",
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof button> {}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => (
    <button
      className={button({ variant, size, className })}
      ref={ref}
      {...props}
    />
  ),
);
Button.displayName = "Button";
