"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import Analytics from "analytics";

import { type AnalyticsEvents } from "./events";
import loggerPlugin from "./plugins/analyticsLogger";
import { segmentPlugin } from "./plugins/segment";

const isDeployed = process.env.NODE_ENV === "production";
const plugins = isDeployed ? [segmentPlugin] : [segmentPlugin, loggerPlugin];

const AnalyticsTool = Analytics({
  app: "Wrap.Gifts",
  plugins,
});

async function track<K extends keyof AnalyticsEvents>(
  eventName: K,
  payload?: AnalyticsEvents[K],
) {
  return AnalyticsTool.track(eventName, payload);
}

export const analytics = {
  page: AnalyticsTool.page,
  track: track,
  identify: AnalyticsTool.identify,
  reset: AnalyticsTool.reset,
};

export function PageAnalytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    analytics.page().catch(console.error);
  }, [pathname, searchParams]);

  return null;
}
